.contact {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.section-title {
  font-size: 32px;
  font-weight: 700;
}

.contact-container {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0px;
}

.contact-left {
  max-width: 60%;
  font-size: 20px;
}

.download {
  background-color: rgb(127, 204, 255);
  padding: 0px 16px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.download a {
  text-decoration: none;
  padding: 5px;
  color: black;
}

@media only screen and (max-width: 720px) {
  .contact-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contact-left {
    max-width: 100%;
    font-size: 16px;
    margin-bottom: 12px;
  }
}
